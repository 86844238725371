import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isSignedIn, listenAuthChange } from './Firebase';

// ProtectedRoute component to wrap protected routes
const ProtectedRoute = ({ element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        // Check authentication status
        const checkAuth = async () => {
            const signedIn = await isSignedIn();
            setIsAuthenticated(signedIn);
        };

        // Listen to auth changes
        const unsubscribe = listenAuthChange((user) => {
            if (user) {
                // User is signed in
                console.log('User signed in');
                setIsAuthenticated(true);
            } else {
                // User is signed out
                console.log('User signed out');
                setIsAuthenticated(false);
            }
        });

        // Initial authentication check
        checkAuth();

        return () => unsubscribe(); // Clean up the subscription on unmount
    }, []);

    if (isAuthenticated === null) {
        // Optionally show a loading indicator while checking auth status
        return <div>Loading...</div>;
    }

    return isAuthenticated ? element : <Navigate to="/signin" />;
};

export default ProtectedRoute;
