import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { expertiseOptions } from "../../Constants/DummyInfo";
import { Anta } from "../../Constants/Fonts";
import AddIcon from '@mui/icons-material/Add';
import { validateEmail } from "../../util/Checker";

export default function ExpertForm({updateAgent, agent, updateComplete, disabled=false}){

    const fields = [
        {name: "name", title: "Name", props:{required:true, placeholder: "John doe", disabled:disabled}, size: 6},
        {name: "title", title: "Title", props:{required:true, placeholder: "Incident Response Specialist", disabled:disabled}, size: 6},
        {name: "about", title: "About", props:{multiline:true, required:true, rows:5, placeholder: "A little bio about your expert", disabled:disabled}, size:12}
    ]

    const [searchString, setSearchString] = useState("")

    useEffect(()=>{
        if(!updateComplete)
            return

        let complete = true
        if(!agent.email || !validateEmail(agent.email)){
            updateComplete(false)
            return;
        }
        //see if there are any incomplete fields
        for(const f of fields){
            if(f.props.required && !agent[f.name]){
                updateComplete(false)
                return;
            }
        }

        complete = Boolean(complete && agent.expertise)

        updateComplete(complete)
    },[agent])

    return(
        <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
        {!disabled && <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem'  }}>Enter In Your Information for Your Custom Expert Here</Typography>}
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField label="Contact Email" value={agent.email || ""} onChange={(e) => updateAgent("email", e.target.value)} fullWidth required
                error={agent.email && !validateEmail(agent.email)}/>
            </Grid>
            {fields.map((f, index) => (
                <Grid item xs={12} md={f.size || 12}>
                    <TextField {...f.props} label={f.title || f.name} value={agent[f.name]}  onChange={(e) => updateAgent(f.name, e.target.value)} fullWidth/>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Autocomplete
                options={expertiseOptions}
                getOptionLabel={(option) => option.name}
                value={agent.expertise}
                required
                disabled={disabled}
                onChange={(e, v) => updateAgent('expertise', v)}
                renderInput={(params) => 
                <TextField {...params} label="Expertise" placeholder="Cybersecurity Expert" required/>}
                />
            </Grid>
            <Grid item xs={12}>
            <Autocomplete
            multiple
            freeSolo
            value={agent.keywords || []}
            options={[]}
            inputValue={searchString}
            onChange={(e, v) => {setSearchString(""); updateAgent('keywords', v)}}
            onInputChange={(e) => setSearchString(e.target.value)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Keywords"
                    placeholder="some quick keywords about your expert"
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position="end" sx={{mr:1}}>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    if (searchString) {
                                        console.log('searchString', searchString)
                                        updateAgent('keywords', [...(agent.keywords || []), searchString]);
                                        setSearchString("")
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                        </>
                    ),
                    }}
                />
            )}
        />
            </Grid>
        </Grid>
        </Box>
    )
}