import Markdown from 'react-markdown';
import { Box, Button } from '@mui/material';
import './markdown.css';
import { useHover } from '../../util/hooks';


function ChatMessage({ data, setSourcesOpen }) {
  const { hovered, ref } = useHover();

  return (
    <div ref={ref}>
      {data.role === "user" ? 
        <Box display="flex" justifyContent="flex-end">
          <Box bgcolor="background.paper" sx={{ maxWidth: '80%', borderRadius: 15, p: 1, pl: 3, pr: 3 }}>
            <Markdown className="markdown">{data.message}</Markdown>
          </Box>
        </Box>
        :
        <div className='markdown'>
          <Markdown>{data.message}</Markdown>
          {data.data && 
            <Box display="flex" height={30}>
              {/* {hovered ? */}
                <Button 
                  color="primary" 
                  variant="outlined" 
                  size="small" 
                  onClick={() => setSourcesOpen(data.data)}
                >
                  Sources
                </Button>
                {/* :null
              } */}
            </Box>
          }
        </div>
      }
    </div>
  );
}

export default ChatMessage;
