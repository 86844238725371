import { Box } from "@mui/material"
import { doubleGradient, singleGradient } from "../../Constants/Colors"
import { StyledAppBar } from "./AppBar"

export default function BackgroundTemplate({children, navbar=true}){
    return(
    <Box sx={{background:{xs: singleGradient, md: doubleGradient}, minHeight:'100vh', maxWidth:'100vw', display:'flex', flexDirection:'column'}}>
        {navbar && <StyledAppBar/>}
        {children}
    </Box>
    )
}