import { CircularProgress, IconButton, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MicIcon from '@mui/icons-material/Mic';
import { makeAuthenticatedRequest } from "../../util/Firebase";

//silence Duration is the amount of ms it will wait until submitting if it no longer detects audio
//silence threshold helps decide what is considered "silent"
export default function RecordButton({ onTranscribe = () => {}, silenceDuration=1000, silenceThreshold=0.01 }) {
    const [isRecording, setIsRecording] = useState(false);
    const [isTranscribing, setIsTranscribing] = useState(false);

    const theme = useTheme()

    // References to MediaRecorder and AudioContext
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const mediaStreamRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const silenceStartTimeRef = useRef(null);

    const getTranscription = async (audioBlob) => {
        setIsTranscribing(true);
        const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
        const formData = new FormData();
        formData.append('audio', audioFile);
        makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/whisper/transcribe`, {
            method: "POST",
            body: formData
        }).then(async (resp) => {
            if (!resp.ok) {
                console.error('Error occurred', await resp.text());
            } else {
                const transcription = (await resp.json()).transcription;
                console.log(transcription);
                onTranscribe(transcription);
            }
            setIsTranscribing(false);
        }).catch(err => {
            setIsTranscribing(false);
            console.error(err);
        });
    };

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaStreamRef.current = stream;

        // Initialize MediaRecorder
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            getTranscription(audioBlob);
            audioChunksRef.current = [];
        };

        // Initialize AudioContext and AnalyserNode
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);

        // Set up silence detection
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        silenceStartTimeRef.current = Date.now();

        const detectSilence = () => {
            analyserRef.current.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
            
            if (average < silenceThreshold) {
                if (Date.now() - silenceStartTimeRef.current >= silenceDuration) {
                    stopRecording(); // Stop recording after 1 second of silence
                }
            } else {
                silenceStartTimeRef.current = Date.now(); // Reset silence timer
            }

            requestAnimationFrame(detectSilence);
        };

        detectSilence();

        // Start recording
        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
        setIsRecording(false);
    };

    return (
        <>
            {!isTranscribing ?
                <IconButton onClick={isRecording ? stopRecording : startRecording}
                sx={{color: isRecording ? theme.palette.error.main : theme.palette.primary.main}}>
                    <MicIcon />
                </IconButton>
                :
                <CircularProgress size={24} />
            }
        </>
    );
}
