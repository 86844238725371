import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Landing from './pages/Landing';
import SignIn from './pages/SignIn';
import ProtectedRoute from './util/ProtectedRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from './Constants/Colors';
import Search from './pages/Search';
import ChatBot from './components/chatbot/ChatBot';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FindExperts from './pages/FindExperts';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Register from './pages/Register';
import ScrollToTop from './components/general/ScrollToTop';
import Settings from './components/chatbot/Settings';
import ComingSoon from './pages/ComingSoon';
import CreateExpert from './pages/CreateExpert/CreateExpert.js';

export const navRoutes = [
  {
    name: "Create",
    path: "/experts/create",
    element: <ProtectedRoute element={<CreateExpert/>}/>
  },
  {
    name: "Company",
    path: "/company",
    element: <ProtectedRoute element={<About/>}/>
  },
  {
    name: "Registration",
    path: "/register",
    element: <ProtectedRoute element={<Register/>}/>
  },
  {
    name: "Settings",
    path: "/settings",
    element: <ProtectedRoute element={<Settings/>}/>
  },
  
]

const router = createBrowserRouter([
  ...navRoutes,
  {
    name: "Home",
    path: "/",
    element: <ProtectedRoute element={<Landing/>}/>,
  },
  {
    path: "/signin",
    element: <SignIn/>
  },
  {
    path: "/search",
    element: <ProtectedRoute element={<Search/>}/>
  },
  {
    path: "/experts",
    element: <ProtectedRoute element={<FindExperts/>}/>
  },
  {
    path: "/experts/chat",
    element: <ProtectedRoute element={<ChatBot/>}/>
  },
  {
    path: "/*",
    element: <NotFound/>
  }
]);

const theme = createTheme(themeOptions)

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router}>
          <ScrollToTop/>
        </RouterProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
