import { Button, Modal, TextField, MenuItem, Box, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState, useMemo } from "react";
import { getEmbedModels, getLlms, createExpert, updateExpert } from "./actions";
import { FieldInput } from "./FieldInput"; // Ensure this component uses MUI
import StructuredDocumentsSettings from "./StructuredDocumentsSettings";

const defaultValues = {
  name: "",
  embed_model: "",
  technical_analysis_prompt: "",
  technical_analysis_llm: "",
  technical_analysis_docs: 0,
  case_study_prompt: "",
  case_study_llm: "",
  case_study_docs: 0,
  information_retrieval_prompt: "",
  information_retrieval_llm: "",
  knowledge_integration_prompt: "",
  knowledge_integration_llm: "",
  response_generator_prompt: "",
  response_generator_llm: "",
  persona_prompt: "",
  persona_llm: "",
}

function getStringBeforeLastUnderscore(input) {
  const lastIndex = input.lastIndexOf('_');
  if (lastIndex === -1) {
    return input;
  }
  return input.substring(0, lastIndex);
}

export default function ExpertSettings({expert}) {
  const queryClient = useQueryClient();
  const [data, setData] = useState(null);
  const embedModels = useQuery({
    queryKey: ['embed_models'],
    queryFn: getEmbedModels,
  });
  const llms = useQuery({queryKey: ["llms"], queryFn: getLlms})
  const createMutation = useMutation({
    mutationKey: ['create_expert'],
    mutationFn: (data) => { return createExpert(data) },
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['experts']}) }
  })
  const updateMutation = useMutation({
    mutationKey: ['edit_expert'],
    mutationFn: (data) => { return updateExpert(data) },
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['experts']}) }
  })
  console.log(data)
  const fields = useMemo(() => {
    return [{
      name: "name",
      label: "Name",
      type: "string",
    },
    {
      name: "embed_model",
      label: "Text Embedding Model",
      type: "select",
      options: embedModels.data || [],
    },
    {
      name: "technical_analysis_enabled",
      label: "Technical Analysis Agent",
      type: "boolean",
    },
    {
      name: "technical_analysis_prompt",
      label: "Technical Analysis Prompt",
      type: "text",
    },
    {
      name: "technical_analysis_llm",
      label: "Technical Analysis LLM",
      type: "select",
      options: llms.data || [],
    },
    {
      name: "technical_analysis_docs",
      label: "Technical Analysis # Docs",
      type: "int",
    },
    {
      name: "case_study_enabled",
      label: "Case Study Agent",
      type: "boolean",
    },
    {
      name: "case_study_prompt",
      label: "Case Study Prompt",
      type: "text",
    },
    {
      name: "case_study_llm",
      label: "Case Study LLM",
      type: "select",
      options: llms.data || [],
    },
    {
      name: "case_study_docs",
      label: "Case Study # Docs",
      type: "int",
    },
    {
      name: "information_retrieval_enabled",
      label: "Information Retrieval Agent",
      type: "boolean",
    },
    {
      name: "information_retrieval_prompt",
      label: "Information Retrieval Prompt",
      type: "text",
    },
    {
      name: "information_retrieval_llm",
      label: "Information Retrieval LLM",
      type: "select",
      options: llms.data || [],
    },
    {
      name: "knowledge_integrator_enabled",
      label: "Knowledge Integrator Agent",
      type: "boolean",
    },
    {
      name: "knowledge_integrator_prompt",
      label: "Knowledge Integrator Prompt",
      type: "text",
    },
    {
      name: "knowledge_integrator_llm",
      label: "Knowledge Integrator LLM",
      type: "select",
      options: llms.data || [],
    },
    {
      name: "response_generator_enabled",
      label: "Response Generator Agent",
      type: "boolean",
    },
    {
      name: "response_generator_prompt",
      label: "Response Generator Prompt",
      type: "text",
    },
    {
      name: "response_generator_llm",
      label: "Response Generator LLM",
      type: "select",
      options: llms.data || [],
    },
    {
      name: "persona_enabled",
      label: "Persona Agent",
      type: "boolean",
    },
    {
      name: "persona_prompt",
      label: "Persona Prompt",
      type: "text",
    },
    {
      name: "persona_llm",
      label: "Persona LLM",
      type: "select",
      options: llms.data || [],
    },
  ];
  },[embedModels.data, llms.data])

  function handleChange(fieldName, value) {
    setData({ ...data, [fieldName]: value });
  }

  function handleSave() {
    if (typeof expert === "object" && expert.id) {
      updateMutation.mutate({ id: expert.id, ...data });
    } else {
      createMutation.mutate(data);
    }
  }

  useEffect(() => {
    if (typeof expert === "object") {
      console.log(expert)
      let newData = { ...expert };
      delete newData.id;
      setData(newData);
    } 
  }, [expert, embedModels.data, llms.data]);

  const shownFields = useMemo(() => {
    if(!data) return [];
    return fields.filter((field) => {
      let show_field = getStringBeforeLastUnderscore(field.name) + "_show"
      let enabled_field = getStringBeforeLastUnderscore(field.name) + "_enabled"
      if (fields.some(n=>n.name === enabled_field) && (!(show_field in data) || !data[show_field]) && !field.name.endsWith("_enabled")) {
        return false;
      }
      return true;
    });
  }, [fields, data]);

  if (!embedModels.data || !llms.data || !data) {
    return null;
  }

  return (
    <Box sx={{ p: 2, width: '90%' }}>
      {shownFields.map((field) => (
        <FieldInput
          key={field.name}
          value={data[field.name]}
          handleChange={handleChange}
          data={data}
          {...field}
          sx={{ mb: 5}}
        />
      ))}
      <Button variant="contained" onClick={() => handleSave()}>
        Save
      </Button>
      <Box>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Structured Documents
        </Typography>
        <Box>
          {expert?.structured_documents?.map((doc, i) => (
            <StructuredDocumentsSettings document={doc} key={i}/>
          ))}
        </Box>
      </Box>
    </Box>
  );
}