import { Autocomplete, Box, Chip, Grid, TextField, Typography } from "@mui/material";
import { doubleGradient, themeOptions } from "../Constants/Colors";
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import { StyledAppBar } from "../components/general/AppBar";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router";
import { dummyExperts, expertiseOptions } from "../Constants/DummyInfo";
import CustomButton from "../components/general/CustomButton";
import { ipsum } from "../Constants/ipsum";
import ReadMore from "../components/general/ReadMore";
import { Anta, fontSizes } from "../Constants/Fonts";
import { AnimatePresence, motion } from "framer-motion";
import BackgroundTemplate from "../components/general/BackgroundTemplate";

const dummyPitches = [
    "10+ yrs Experience",
    "Worked at Crowdstrike",
    "CISSP Member",
    "CompTIA Pentesting Certified",
    "Wrote Linux Kernel"
]

const slideInVariants = {
    hidden: { x: '100vw' },
    visible: (index) => ({
      x: 0,
      transition: {
        delay: index * 0.2, // Adjust delay to stagger the animation
        type: 'spring',
        stiffness: 50,
      },
    }),
  };

export default function FindExperts({}){
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [searchQuery, setSearchQuery] = useState(queryParams.get('query') || "")
    const [matchingExperts, setMatchingExperts] = useState(dummyExperts)

    const navigate = useNavigate()
    const theme = useTheme()

    

    const search = (query = searchQuery) => {
        if(query)
            navigate(`/experts?query=${query}`)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          search();
        }
    };

    useEffect(()=>{
        const q = queryParams.get('query')
        console.log("searchQuery", q)
        const newExperts = q ? dummyExperts.filter((e) => (!q || e.expertise === q)) : [...dummyExperts]
        console.log('matchingExperts', newExperts)
        setMatchingExperts(newExperts)
    }, [queryParams.get('query')])

    return(
        <BackgroundTemplate>
            <Grid container sx={{width:'100%', display:'flex'}}>
                <Grid item container xs={12} lg={3} sx={{padding:'1rem', paddingLeft:"2rem"}}>
                <Autocomplete
                        freeSolo
                        
                        value={searchQuery}
                        fullWidth
                        onChange={(event, newValue) => {
                            search(newValue)
                        }}
                        options={expertiseOptions.map((option) => option.name)}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Industry" 
                            onKeyDown={handleKeyDown}
                            // labelProps={{background:"transparent"}}
                            label="Industry"
                            variant="outlined"
                            // sx={{background:theme.palette.background.paper,borderRadius:2}}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />)}
                    />
                </Grid>
                <Grid item container xs={12} lg={9} sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%',
                    paddingTop:'1rem', overflowX: 'hidden'
                }}>
                    {
                        matchingExperts.map((e,index) => (
                            <motion.div
                            style={{display:'flex', width:'100%', justifyContent:'center'}}
                            key={e.id}
                            custom={index}
                            initial="hidden"
                            animate="visible"
                            variants={slideInVariants}
                            >
                            <ExpertDisp info={e} onClick={() => navigate(`/experts/chat?expertid=${e.id}`)}/>
                            </motion.div>
                        ))
                    }
                </Grid>
            </Grid>
        </BackgroundTemplate>
    )
}

const ExpertDisp = ({ info = {}, onClick }) => {
    const [isHover, setIsHover] = useState(false)
    const theme = useTheme()
    const pitches = info.pitches || dummyPitches;
    
    const chatVariants = {
    initial: { opacity: 0, scale: 0.9 },
    exit: { opacity: 0, scale: 0.9 },
    enter: { opacity: 1, scale: 1 },
    animate: { opacity: 1, scale: 1 },
    };

    return (
        <Grid container 
        sx={{ minHeight: '18rem', width: '75rem', maxHeight: '100%', maxWidth: '100%', flexDirection: 'row', display: 'flex', padding:'1rem' }}>
            <Grid item container xs={8} md={2} sx={{ height:'14rem', display: 'flex' }}>
            {!isHover ? (
            <motion.div
                key="image"
                style={{ width: '100%', height: '100%' }}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={chatVariants}
                onHoverStart={() => setIsHover(true)}
                onHoverEnd={() => setIsHover(false)}
            >
                <img
                src={info.image || require('../assets/images/john_doe.jpg')}
                alt="Expert"
                style={{ width: '100%', height: '100%', borderRadius: 12 }}
                />
            </motion.div>
            ) : (
            <motion.div
                key="chatbox"
                style={{ width: '100%', height: '100%' }}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={chatVariants}
                onHoverStart={() => setIsHover(true)}
                onHoverEnd={() => setIsHover(false)}
                whileTap={{scale:0.95}}
            >
                <Box
                onClick={onClick}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    cursor:'pointer',
                    background: theme.palette.primary.main,
                    borderRadius: 3,
                }}
                >
                <ChatIcon sx={{ color: theme.palette.text.contrast, fontSize: 100 }} />
                <Typography color={theme.palette.text.contrast}>Chat Now</Typography>
                </Box>
            </motion.div>
            )}
            </Grid>
            <Grid item container sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingLeft:'1rem' }} md={9}>
                <Grid container sx={{display: 'flex', flexDirection: 'column', maxWidth:'100%', flex:1}} rowGap={1}>
                    <Box>
                    <Typography sx={{fontSize: fontSizes.default+8}}>{info.name || "John Doe"}</Typography>
                    <Typography sx={{fontSize: fontSizes.default+2}}>{info.title || "Cybersec Specalist"}</Typography>
                    </Box>
                    <Grid conainer sx={{ display: {xs:'none', md:'flex'},flexWrap:'wrap', maxWidth:'100%' }} gap={2}>
                        {
                            pitches.map((p, index) => (
                                <motion.div
                                whileHover={{scale:1.05}}
                                style={{display:'flex', cursor:'default'}}
                                key={`${p}_${index}`}
                                >
                                    <Chip label={<Typography fontSize={fontSizes.default}>{p}</Typography>}/>
                                </motion.div>

                            ))
                        }
                    </Grid>
                    <ReadMore text={info.about || ipsum} maxHeight={{xs:0, sm:100}}/>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', paddingX: '2rem' }}>
                    <Grid item xs={6} md={4}>
                        <CustomButton onClick={onClick}>Chat Now</CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
};
