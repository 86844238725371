import { Box, Button, createTheme, Divider, FilledInput, FormControl, Grid, IconButton, InputAdornment, InputLabel, Paper, TextField, ThemeProvider, Typography } from "@mui/material";
import { useState } from "react";
import { emailSignIn } from "../util/Firebase";
import { useLocation, useNavigate } from "react-router";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function SignIn({}){
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate()

    const updateUser = (e) => {
        setUsername(e.target.value)
    }

    const updatePassword = (e) => {
        setPassword(e.target.value)
    }

    const updateError = (e) => {
        console.error(e)
        setError(e)
    }

    const signIn = async () =>{
        if(!username){
            updateError("Email Cannot be empty")
            return;
        }
        if(!password){
            updateError("Password cannot be empty")
            return;
        }

        try{
            //now we're trying to sign in
            await emailSignIn(username, password)
            let orig = queryParams.get('orig')
            if(!orig)
                navigate('/')
            else{
                if(Array.isArray(orig))
                    navigate(orig[0])
                else
                    navigate(orig)
        }
        }catch(e){
            console.error("error caught")
            switch(e.code){
                case "auth/invalid-email":
                    updateError("Invalid Email")
                    break;
                case "auth/missing-email":
                    updateError("Missing Email")
                    break;
                case "auth/missing-password":
                    updateError("Missing Password")
                    break;
                case "auth/invalid-credential":
                    updateError("Incorrect Email or Password, Please try again")
                    break;
                case "auth/wrong-password":
                    updateError("Incorrect Email or Password, Please try again")
                    break;
                default:
                    console.error("testing")
                    console.error(e.message)
                    updateError(e.message)
                    break;
            }
        }
    }
    const theme = createTheme()
    return(
        <ThemeProvider theme={theme}>
        <Grid container sx={{flex:1, display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
        <Grid item container xs={12} sm={12} md={8} lg={8} sx={{height:'40rem', maxHeight:'100%', padding:'1%'}}>
            <Paper sx={{height:'100%', width:'100%', padding:'1%', borderRadius:12, flexDirection:'column', gap:10}}>
                <Box sx={{minHeight:'4rem', display:'flex', flexDirection:'column'}}>
                    <Typography align="center" variant="h5">Please Sign In</Typography>
                    <Divider/>
                </Box>
                <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:10}}>
                    <TextField label="Email" placeholder="Email" value={username} onChange={updateUser} fullWidth/>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                        <FilledInput
                            id="filled-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={updatePassword}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    {error && <Typography color="error">{error}</Typography>}
                    <Button onClick={signIn}>Sign In</Button>
                </Box>

            </Paper>
            
        </Grid>            
        </Grid>
        </ThemeProvider>
    )
}