import { Box, Typography, Button, useTheme } from '@mui/material';
import AppBar from '../components/general/AppBar';
import { Anta, fontSizes } from '../Constants/Fonts';
import { backgroundVideo } from '../Constants/Videos';
import CustomButton from '../components/general/CustomButton';
import { doubleGradient, singleGradient } from '../Constants/Colors';
import { useNavigate } from 'react-router';
import BackgroundTemplate from '../components/general/BackgroundTemplate';
import RecordButton from '../components/chatbot/RecordButton';

export default function ComingSoon() {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <BackgroundTemplate navbar={false}>

      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: { xs: 'column', md: 'row' } }}>
        {/* Gradient Section */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box sx={{ width: '40rem', maxWidth: '100%', padding: '5rem', display: 'flex', flexDirection: 'column', }}>
            <Box sx={{minHeight: {md: '25rem', xs:'20rem'}}}>
            <Typography
              sx={{ fontSize: { xs: fontSizes.titleSmall, md: fontSizes.title }, color: theme.palette.text.title, fontFamily: Anta.fontFamily}}
            >
                Coming Soon!
            </Typography>
              </Box>
              <Box sx={{}}>
                <CustomButton onClick={() => navigate(-1)}
                style={{height:'4rem'}}
                buttonProps={{variant:"contained", fullWidth:true}}>
                  <Typography fontSize={fontSizes.default+10}>Go Back</Typography>
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Video Section */}
        <Box sx={{ flex: 1, position: 'relative', display: {lg:'flex', xs:'none'} }}>
          <video
            autoPlay
            loop
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </BackgroundTemplate>
  );
}
