import { Box, Typography, Button, useTheme } from '@mui/material';
import AppBar from '../components/general/AppBar';
import { Anta, fontSizes } from '../Constants/Fonts';
import { backgroundVideo } from '../Constants/Videos';
import CustomButton from '../components/general/CustomButton';
import Typewriter from 'typewriter-effect';
import { useState } from 'react';
import { doubleGradient, singleGradient } from '../Constants/Colors';
import { useNavigate } from 'react-router';
import BackgroundTemplate from '../components/general/BackgroundTemplate';
import { useIsMdScreen } from '../util/hooks';

export default function Landing() {
  const [startSecondTypewriter, setStartSecondTypewriter] = useState(false);
  const isMd = useIsMdScreen()
  const theme = useTheme()
  const navigate = useNavigate()

  const fontSize = isMd ? fontSizes.subtitle : fontSizes.subtitleSmall;
  const inflectionSize = isMd ? fontSize - 20 : fontSize - 15
  const fontColor = theme.palette.primary.main;
  const fontFamily = Anta.fontFamily;

  return (
    <BackgroundTemplate>

      <Box sx={{ display: 'flex', flexGrow: 1, marginBottom:'1rem', flexDirection: { xs: 'column', md: 'row' } }}>
        {/* Gradient Section */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box sx={{ width: '40rem', maxWidth: '100%', display: 'flex', flexDirection: 'column', paddingX:'5rem'}}>
            <Box sx={{height:{xs: '8rem', md:'10rem'}, width:'fit-content'}}>
              <img
                  src={require('../assets/images/logo.png')}
                  style={{ width: '100%', height: '100%'}}
              />
            </Box>
            <Box sx={{minHeight: '20rem'}}>
            <Typography
              sx={{ fontSize: { xs: fontSizes.titleSmall, md: fontSizes.title }, color: theme.palette.primary.main, fontFamily: Anta.fontFamily}}
            >
              {startSecondTypewriter ? 'SinSen.Ai' :
               <Typewriter
               options={{
                 // strings: 'SinSen.Ai',
                 autoStart: true,
                 loop: false,
                 delay: 50,
                 cursor: "_",
               }}
               onInit={(typewriter) => {
                 typewriter
                   .typeString('SinSen.Ai')
                   .callFunction(() => {
                     // Start the second typewriter effect after the first one finishes
                     setStartSecondTypewriter(true);
                   })
                   .start();
               }}
             />}
             
              </Typography>
              {startSecondTypewriter && (
                <Typography sx={{ fontSize: { xs: fontSizes.subtitleSmall, md: fontSizes.subtitle }, color: theme.palette.primary.main, fontFamily: Anta.fontFamily}}>
                <Typewriter
                  options={{
                    cursor: "_",
                    autoStart: true,
                    loop: false,
                    delay: 50
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `<span style="font-size: ${fontSize}px; color: ${fontColor}; font-family: ${fontFamily};">Artificial Intelligence, Human Experience</span><br/>`
                      )
                      .typeString(
                        `<span style="font-size: ${inflectionSize}px; color: ${fontColor}; font-family: ${fontFamily};">Powered by Inflection.Ai</span>`
                      )
                      .pauseFor(2000)
                      .changeDeleteSpeed(5)
                      .deleteChars("Powered by Inflection.Ai".length + 1)
                      .callFunction(() => {})
                      .start();
                  }}
            />
          </Typography>
              )}
              </Box>
              <Box sx={{}}>
                <CustomButton onClick={() => navigate('/search')}
                style={{height:'4rem'}}
                buttonProps={{variant:"contained", fullWidth:true}}>
                  <Typography fontSize={{
                    md:fontSizes.default+10,
                    xs: fontSizes.default
                  }}>Explore</Typography>
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Video Section */}
        <Box sx={{ flex: 1, position: 'relative', display: {lg:'flex', xs:'none'} }}>
          <video
            autoPlay
            loop
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </BackgroundTemplate>
  );
}
