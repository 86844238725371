import { Box, Divider, Grid, IconButton, Paper, Snackbar, TextField, Typography, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { doubleGradient } from "../Constants/Colors";
import { StyledAppBar } from "../components/general/AppBar";
import BackgroundTemplate from "../components/general/BackgroundTemplate";
import CustomButton from "../components/general/CustomButton";
import { useEffect, useState } from "react";
import { Anta, fontSizes } from "../Constants/Fonts";
import { whisperSocket } from "../Socket";
import { makeAuthenticatedRequest } from "../util/Firebase";
  


export default function Register({}){
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [openMessage, setOpenMessage] = useState(false)

    //Some code from when i was testing websockeds
    //TODO: remove
    // const [receivedChunks, setReceivedChunks] = useState([]);
    // useEffect(() => {
    //     if(!whisperSocket.connected){
    //         console.log('trying to connect')
    //         whisperSocket.connect()
    //     }
    //     // Connect to the Socket.IO server
    //     // Listen for messages from the server
    //     whisperSocket.on("message", (data) => {
    //         console.log("Message from server:", data);
    //     });

    //     // Clean up the socket connection on component unmount
    //     return () => {
    //         whisperSocket.off("message");
    //     };
    // }, []);

    // const sendMessage = () => {
    //     if (whisperSocket) {
    //         whisperSocket.emit("message", { data: "Hello, server!" });
    //     }
    // };

    const onSubmit = () =>{
        console.log('press')
        console.log(name, email, phone)
        setOpenMessage(true)
        //handleStreamText()
        // sendMessage()
    }
    
    const theme = useTheme()

    return(
        <BackgroundTemplate>
            <Box sx={{padding:'0.5rem', justifyContent:'center', alignItems:'center', display:'flex'}}>
                <Box sx={{width:'62rem', maxWidth:'100%', minHeight:'30rem', background:theme.palette.background.paper,
                    border:`1px ${theme.palette.primary.main} solid`, borderRadius:12, padding:'1rem',
                    display:'flex', flexDirection:'column',
                }}>
                    <Box sx={{justifyContent:'center', alignItems:'center', margin:'1rem'}}>
                        <Typography align="center" sx={{fontSize:fontSizes.default+8, color:theme.palette.primary.main, fontFamily:Anta.fontFamily}}>Register for Sinsen</Typography>
                        <Divider/>
                    </Box>
                    <Grid container gap={2} sx={{flex:1}}>
                        <Grid item md={6} xs={12}>
                            <TextField label="Full Name" placeholder="Full Name" required fullWidth/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField label="Email" placeholder="Email" required fullWidth/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField label="Phone Number" placeholder="Phone Number" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomButton onClick={onSubmit}>Submit</CustomButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar
            open={openMessage}
            onClose={() => setOpenMessage(false)}
            autoHideDuration={6000}
            message="Registration is Currently Disabled.  Please check again another time!"
            color="error"
            action={
            <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenMessage(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            }
            />
        </BackgroundTemplate>
    )
}