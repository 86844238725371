import { Box, Button, Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FieldInput } from "./FieldInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStructuredDocument } from "./actions";


export default function StructuredDocumentsSettings({ document, ...props }) {
  const queryClient = useQueryClient();
  const [showOptions, setShowOptions] = useState(false);
  const [data, setData] = useState(document);
  const updateMutation = useMutation({
    mutationKey: ['edit_expert'],
    mutationFn: (e) => { return updateStructuredDocument(e) },
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['experts']}) }
  })

  useEffect(() => {
    setData(document);
  }, [document]);

  const fields = useMemo(() => {
    return [{
      name: "name",
      label: "Name",
      type: "string",
    },
    {
      name: "metadata_description",
      label: "Metadata",
      type: "text",
    },
    {
      name: "sql_query_prompt",
      label: "SQL Query Prompt",
      type: "text",
    },
    {
      name: "sql_answer_prompt",
      label: "SQL Answer Prompt",
      type: "text",
    },
    {
      name: "keyword_prompt",
      label: "Keyword Prompt",
      type: "text",
    },
  ]}, []);

  return (
    <Box>
      <Typography>{document.name}</Typography>
      <FormControl variant="outlined" fullWidth {...props}>
        <FormControlLabel control={
          <Checkbox
            checked={showOptions}
            onChange={(e) => setShowOptions(e.target.checked)}
          />
        } 
        label={"Show Options"} 
        />
      </FormControl>
      {showOptions && (
        <Box>
          {fields.map((field) => (
            <FieldInput
              key={field.name}
              value={data[field.name]}
              handleChange={(name, value) => setData({ ...data, [name]: value })}
              {...field}
              sx={{ mb: 5}}
            />
          ))}
          <Button variant="contained" onClick={() => {
              updateMutation.mutate({ ...data });
          }}>
            Save
          </Button>
        </Box>
      )}
    </Box>
  )
}
