import { Box, Chip, IconButton, Typography, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import _ from 'lodash';
import EllipsisChip from './EllipsisChip';
import { UnqiueFile } from '../../classes/UniqueFile';

export const defaultFileTypes = {
    'text/plain': ['.txt'],
    // 'text/csv': ['.csv'],
    // 'application/vnd.ms-excel': ['.xls'],
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/pdf': ['.pdf'],
    // 'text/markdown': ['.md'],
    'application/rtf': ['.rtf'],
    // 'text/html': ['.html', '.htm'],
    // 'application/xml': ['.xml'],
    // 'application/json': ['.json']
  }

  export const defaultExtensions = ['.txt', '.doc', '.docx', '.pdf', '.pdf']

export default function FileUpload({onUpdate, onReject, displayStr, files, accept, maxMb, uniqueFiles}){
    const theme = useTheme()
    
    const handleDrop = (acceptedFiles) => {
        if(uniqueFiles){
            acceptedFiles = acceptedFiles.map(f => new UnqiueFile(f))
        }

        console.log(maxMb * 1024)
        console.log('accepted_files', acceptedFiles, files)
        console.log('test')
        onUpdate([...acceptedFiles, ...files])
    }

    const handleDelete = (index) => {

        onUpdate([
            ...files.slice(0, index), 
            ...files.slice(index + 1)
        ]);
    }

    const handleError = (files) => {
        console.error(files)
        onReject(files)
    }

    const getExtensionString = () => {

        //accept is of the form
        //mimetype: [extensions] so iterate through extensions
        
        // if(_.isEqual(accept, defaultFileTypes))
        //     return undefined

        let extensions = []

        for(let [key, ext] of Object.entries(accept)){
            extensions = extensions.concat(ext)
        }

        if(extensions.length == 0)
            return undefined
        
        return "Allowed File Types: " + extensions.join(", ")
    }

    const getSizeString = () => {
        return maxMb ? `Max Size: ${maxMb} MB` : undefined
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: handleDrop,
        onDropRejected: handleError,
        accept: accept,
        multiple: true,
        maxSize: maxMb ? (maxMb * 1024 * 1024) : undefined
      });

    return(
        <Box {...getRootProps()} 
            sx={{border: '2px grey dashed', minHeight:{xs:'10rem', md:'15rem'}, borderRadius:5,
            width:"100%", background:theme.palette.background.paper, alignItems:'center', display:'flex',
            cursor:'pointer', flexDirection:'column'}}>
            <input {...getInputProps()} />
            <p>{displayStr}</p>
            <>
                <p>{getExtensionString()}</p>
                <p>{getSizeString()}</p>
            </>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden', justifyContent:'center', alignItems:'center', maxWidth:'100%' }}>
                {files.map((file, index) => (
                <EllipsisChip
                key={index}
                label={uniqueFiles ? file.fileName : file.name}
                onDelete={(e) => {
                    e.preventDefault();
                    handleDelete(index);
                    }}
                />
                ))}
            </Box>
        </Box>
    )
}

FileUpload.defaultProps = {
    files: [],
    accept: defaultFileTypes,
    displayStr: "Drag Files Here or Click to Upload",
    maxMb: 50,
    onReject: () => {},
    uniqueFiles:true
}