import { useEffect, useRef, useState } from 'react'
import { Box, TextField, IconButton, CircularProgress, Grid, Typography, Chip, useTheme, useMediaQuery, Tooltip } from '@mui/material';
import { useChatCompletion } from './actions';
import { ArrowUpward, Clear, UploadFile } from '@mui/icons-material';
import ChatMessage from './ChatMessage';
import SourcesModal from './SourcesModal';
import { doubleGradient } from '../../Constants/Colors';
import ReadMore from "../general/ReadMore";
import AppBar from '../general/AppBar';
import { fontSizes } from "../../Constants/Fonts";
import { ipsum } from "../../Constants/ipsum";
import { useLocation } from 'react-router';
import { dummyExperts, testEmails } from '../../Constants/DummyInfo';
import { useQueryClient } from '@tanstack/react-query';
import RecordButton from './RecordButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { getUserEmail, makeAuthenticatedRequest } from '../../util/Firebase';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import CustomButton from '../general/CustomButton';
import DownloadChatButton from './DownloadChatButton';
import AdminOnly from '../general/AdminOnly';

const dummyPitches = [
  "10+ yrs Experience",
  "Worked at Crowdstrike",
  "CISSP Member",
  "CompTIA Pentesting Certified",
  "Wrote Linux Kernel"
]

const ExpertDisp = ({ info = {}, onClick }) => {
  const theme = useTheme()
  const pitches = info.pitches || dummyPitches;
  
  return (
      <Grid container sx={{ flexDirection: 'column', padding:'1rem'}}>
          <Grid item sx={{ width: "100%", maxWidth: 200, display: { xs: 'none', md: 'flex' } }}>
              <img
                  src={info.image || require('../../assets/images/john_doe.jpg')}
                  alt="Expert"
                  style={{ width: '100%', borderRadius:12 }}
              />
          </Grid>
          <Box>
            <Typography sx={{fontSize: fontSizes.defaultSize+4}}>{info.name || "John Doe"}</Typography>
            <Typography sx={{fontSize: fontSizes.defaultSize+2}}>{info.title || "Cybersec Specalist"}</Typography>
          </Box>
          {/* <Grid item container sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingLeft:'1rem' }} md={9}>
              <Grid container sx={{display: 'flex', flexDirection: 'column', maxWidth:'100%', flex:1}} rowGap={1}>
                  <Box>
                  <Typography sx={{fontSize: fontSizes.defaultSize+4}}>{info.name || "John Doe"}</Typography>
                  <Typography sx={{fontSize: fontSizes.defaultSize+2}}>{info.title || "Cybersec Specalist"}</Typography>
                  </Box>
                  <Grid conainer sx={{ display: 'flex',flexWrap:'wrap', maxWidth:'100%' }} gap={1}>
                      {
                          pitches.map((p, index) => (
                              <Grid item>
                                  <Chip label={p} key={`chip_${index}`}/>
                              </Grid>
                          ))
                      }
                  </Grid>
                  <ReadMore text={info.about || ipsum}/>
              </Grid>
          </Grid> */}
      </Grid>

  );
};

function ChatBot() {
  const inputFileRef = useRef(null);
  const location = useLocation()
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isSmAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const queryParams = new URLSearchParams(location.search)
  const expertId = queryParams.get('expertid');
  const expertBackendId = dummyExperts.find((e) => e.id == expertId)?.backendId ?? 1;
  
  const [audioUrl, setAudioUrl] = useState("")
  const [submitAudio, setSubmitAudio] = useState(false)
  const [audioMuted, setAduioMuted] = useState(false)
  const [loadingAudio, setLoadingAudio] = useState(false)
  const [lastAudio, setLastAudio] = useState()
  const [textToTranscribe, setTextToTranscribe] = useState()
  const [playing, setPlaying] = useState(false)
  const autoPlay = useRef(false)

  const [sourcesOpen, setSourcesOpen] = useState(false);
  const [prompt, setPrompt] = useState("");
  const audioRef = useRef(null)
  const messagesEndRef = useRef(null);
  const [mutate, { data, isPending }] = useChatCompletion(expertBackendId);

  const [expert, setExpert] = useState((dummyExperts.find((e) => e.id == expertId) || {}))

  const [currentEmail, setCurrentEamil] = useState(getUserEmail().trim())
  const [selectedFile, setSelectedFile] = useState(null);

  const audioAbort = new AbortController()

  useEffect(()=>{
    console.log(currentEmail, testEmails.includes(currentEmail))
  }, currentEmail)

  useEffect(()=>{
    setExpert((dummyExperts.find((e) => e.id == expertId) || {}))
  }, [expertId])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(()=>{
    return () => {
      //cleaning up url to prevent memory leaks
      if(audioUrl){
        setAudioUrl()
        URL.revokeObjectURL(audioUrl);
      }
    }
  }, [])

  const getSpeech = (text, controller=audioAbort) => {
    setLoadingAudio(true)
      makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/whisper/text_to_speech`,{
        method:"POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({input: text, voice:expert.voice }),
        signal: controller ? controller.signal : undefined
      }).then(async (resp) => {
        if(resp.ok){
          console.log('setting audio url')
          //revoke previous url
          if(audioUrl){
            URL.revokeObjectURL(audioUrl);
          }
          
          const blob = await resp.blob();
          const url = URL.createObjectURL(blob);
          setAudioUrl(url);
          setLastAudio(text)
        }else{
            console.error('error', await resp.text())
        }
        setLoadingAudio(false)
      }).catch(err => {console.error(err); setLoadingAudio(false)})

  }

  useEffect(() => {
    console.log(data)
    scrollToBottom();
    //don't render the audio if muted in order to save on api usage
    //data.data only exists when the message is done typing
    if(data && data[data.length-1].role === 'assistant' && (data.length === 1 || data[data.length-1].data)){
      setTextToTranscribe(data[data.length-1].message)
      if(!audioMuted ){
        // const controller = new AbortController()
        getSpeech(data[data.length-1].message, audioAbort)
  
        return () => audioAbort.abort()
      }else{
        //if failed to render because muted, make sure to turn back off autoplay
        autoPlay.current = false
      }
    }
    
      
  }, [data]);

  useEffect(() => {
    if(submitAudio && prompt){
      handleSubmit()
      setSubmitAudio(false)
    }
  }, [prompt, submitAudio])

  useEffect(() => {
    if(audioUrl && audioRef.current){
      audioRef.current.load()
      console.log('test', autoPlay.current)
      if(autoPlay.current){
        audioRef.current.play().then(() => setPlaying(true)).catch(err => {
          //error for example browser blocks autoplay
          console.error('could not auto play', err)
          //reload audio state
          audioRef.current.load()
          setPlaying(false)
        })
        
        
        autoPlay.current = false
      }
      
    }

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
        setPlaying(false)
      }
    }
      
  }, [audioUrl])

  useEffect(()=>{
    queryClient.setQueryData(["chatCompletion", expertBackendId],[{message: expert.intro || `Hi, I am ${expert.name}, a former ${expert.title}, and a ${expert.role}.   How may I help you?`, role: "assistant"}])
  },[expertId, expertBackendId])

  const handleSubmit = () => {
    if (prompt.trim() !== "") {
      mutate({ prompt, expertId: expertBackendId, "files": [selectedFile] });
      setPrompt("");
      setSelectedFile(null);
      inputFileRef.current.value = "";
      if(loadingAudio){
        audioAbort.abort()
      }
      //pause audio
      if(audioRef.current && !audioRef.current?.paused){
        audioRef.current.pause()
        setPlaying(false)
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleUploadClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <Box display="flex" sx={{width: '100vw', height: '100vh', background: doubleGradient, flexDirection: 'column', overflow: 'hidden'}}>
      <SourcesModal data={sourcesOpen} onClose={() => setSourcesOpen(false)} />
      <Box sx={{ paddingX:'1rem', paddingY:'0.5rem' }}>
        <AppBar style={{ borderRadius: 40 }} />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
        { !isSmAndDown && <Box sx={{ flex: 1, p: 2,  }}>
          <ExpertDisp info={expert}/>
        </Box>}
        <Box sx={{ flex: 2, overflowY: 'auto', p: 2 }}>
            <Box display="flex" alignItems="center" flexDirection="column" overflow="hidden" width="100%" height="100%">
              <Box style={{overflowY: 'auto', width: '100%', display: 'flex', flexGrow: 1}}> 
                <Box display="flex" justifyContent="center" width="100%">
                  <Box style={{width: "90%"}}>
                    {data?.map((chat, i) => (
                      <ChatMessage key={i} data={chat} setSourcesOpen={setSourcesOpen} />
                    ))}
                    <div ref={messagesEndRef} />
                  </Box>
                </Box>
              </Box>
              
              {selectedFile && (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" sx={{ mr: 1 }}>
                      {selectedFile.name}
                    </Typography>
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <Clear />
                    </IconButton>
                  </Box>
                )}
              <Box display="flex" alignItems="center" mb={2} mt={2} width="90%" sx={{flexDirection:'column', display:'flex'}}>
                <Box sx={{flexDirection:{xs:'column', md:'row'}, width:'100%', display:'flex'}}>
                <TextField
                  sx={{background:theme.palette.background.paper}}
                  autoComplete="off"
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Ask the expert..."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <>
                      <IconButton onClick={handleUploadClick} color="primary" size="large">
                        <UploadFile/>
                      </IconButton>
                      <input
                        key={selectedFile}
                        type="file"
                        ref={inputFileRef}
                        style={{ display: 'none' }} // Hide the input element
                        onChange={handleFileChange}
                      />
                      <Box sx={{justifyContent:'center', alignItem:'center', display:'flex'}}>
                        <RecordButton onTranscribe={(t) => {setPrompt(t); setSubmitAudio(true); autoPlay.current = true}}/>
                      </Box>
                      </>
                    ),
                    endAdornment: isPending ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton onClick={handleSubmit} color="primary" size="large">
                        <ArrowUpward />
                      </IconButton>
                    ),
                  }}
                />
                <Box sx={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', display:'flex'}}>
                    <IconButton onClick={() => setAduioMuted(!audioMuted)} sx={{color:audioMuted ? theme.palette.error.main : theme.palette.primary.main}}>
                    {audioMuted ? 
                      <Tooltip title="unmute audio" placement='top'>
                      <VolumeOffIcon color={theme.palette.error.main}/> 
                      </Tooltip>
                      : loadingAudio ?
                      <CircularProgress size={24}/>
                      :
                      <Tooltip title="mute audio" placement='top'>
                        <VolumeUpIcon color={theme.palette.primary.main}/>
                      </Tooltip>
                    }
                    </IconButton>
                  {audioUrl && audioRef.current &&
                    <>
                    {
                      loadingAudio || (data[data.length-1].role === 'assistant' && data.length > 1 && !data[data.length-1].data)?
                      <CircularProgress size={24}/>
                      :
                      playing ? 
                      <IconButton onClick={(e) => {e.preventDefault(); setPlaying(false); audioRef.current.pause()}} sx={{color: theme.palette.primary.main}}>
                        <StopIcon/>
                      </IconButton>
                      :
                      <IconButton onClick={(e) => {
                        e.preventDefault(); 
                        if(textToTranscribe != lastAudio){
                          autoPlay.current = true
                          getSpeech(textToTranscribe)
                          return
                        }
                        setPlaying(true); 
                        audioRef.current.play().then(() => setPlaying(true)).catch(err => console.error('could not auto play', audioUrl, err))
                      }} sx={{color: theme.palette.primary.main}}>
                        <PlayArrowIcon/>
                      </IconButton>
                    }
                    </>
                  }
                </Box>
                </Box>
                <Box sx={{marginTop:'0.5rem', width:'100%'}}>
                  <DownloadChatButton data={data}/>
                </Box>
              </Box>
              
            </Box>
        </Box>
        { !isSmAndDown && <Box sx={{ flex: 1, p: 2 }}/>}
      </Box>
      {<audio src={audioUrl} type="audio/wav" muted={audioMuted} ref={audioRef} 
      onEnded={(e) => {e.preventDefault(); setPlaying(false);  audioRef.current.currentTime = 0;}}/>}
      
    </Box>
  );
}

export default ChatBot;
