import { useEffect } from 'react';
import FileUpload from '../../components/general/FileUpload';
import { Box, Typography } from '@mui/material';
import { Anta } from '../../Constants/Fonts';

export default function CaseStudies({updateAgent, agent, updateComplete, onError}){

    useEffect(()=>{
        updateComplete(Boolean(agent.case_studies && Array.isArray(agent.case_studies) && agent.case_studies.length > 0))
    }, [agent])

    return(
        <Box>
            <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem'  }}>Please Upload All Case Studies For Your Expert</Typography>
            <FileUpload 
            onReject={onError}
            files={(agent.case_studies || [])}
            displayStr={"Place your ordered files here"}
            onUpdate={(newFiles) => updateAgent("case_studies", newFiles)} 
            />
        </Box>
    )
}

