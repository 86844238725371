import { Box, Chip, Divider, Grid, Typography, useTheme } from "@mui/material";
import ExpertForm from "./ExpertForm";
import EllipsisChip from "../../components/general/EllipsisChip";
import { Anta, fontSizes } from "../../Constants/Fonts";
import _ from 'lodash';

export default function Confirm({agent}){
    const theme = useTheme()

    const downloadFile = (file) => {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const boxSpace={width:'100%'}

    const titleText={ fontSize: { xs: fontSizes.titleSmall-30, md: fontSizes.default}, color: theme.palette.primary.main, fontFamily: Anta.fontFamily}

    const basicText = {fontSize: fontSizes.default-2}

    const chipText = {fontSize: fontSizes.default-4}
    
    return(
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', maxWidth:'100%'}}>
            <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem' }}>Please Confirm The Following Information is Correct</Typography>
            <Box sx={boxSpace}>
                <Grid container sx={{display:'flex', width:'100%', justifyContent:'center'}} columnSpacing={0.5}>
                    <Grid item container xs={12}>
                        <Box sx={boxSpace}>
                            <Typography sx={titleText}>Contact Email</Typography>
                            <Divider/>
                            <Typography sx={basicText}>{agent.email}</Typography>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} md={4}>
                        <Box sx={boxSpace}>
                            <Typography sx={titleText}>Name</Typography>
                            <Divider/>
                            <Typography sx={basicText}>{agent.name}</Typography>
                        </Box>
                    </Grid>

                    <Grid item container xs={12} md={4}>
                    <Box sx={boxSpace}>
                        <Typography sx={titleText}>Title</Typography>
                        <Divider/>
                        <Typography sx={basicText}>{agent.title}</Typography>
                    </Box>
                    </Grid>

                    <Grid item container xs={12} md={4}>
                        <Box sx={boxSpace}>
                            <Typography sx={titleText}>Expertise</Typography>
                            <Divider/>
                            <Typography sx={basicText}>{agent.expertise.name}</Typography>
                        </Box>
                    </Grid>

                    <Grid item container xs={12} md={agent.keywords && agent.keywords.length ? 6 : 12}>
                        <Box sx={boxSpace}>
                            <Typography sx={titleText}>About</Typography>
                            <Divider/>
                            <Typography sx={basicText}>{agent.about}</Typography>
                        </Box>
                    </Grid>
                    {agent.keywords && agent.keywords.length > 0 &&
                    <Grid item container xs={12} md={6}>
                        <Box sx={boxSpace}>
                            <Typography sx={titleText}>Keywords</Typography>
                            <Divider/>
                            <Grid container sx={basicText} columnSpacing={1} marginTop={0.25}>
                                {
                                    agent.keywords.map((p, index) => (
                                        <Grid item>
                                            <EllipsisChip label={<Typography sx={chipText}>{p}</Typography>} key={index}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>
            
            <Box sx={boxSpace}>
            <Typography sx={titleText}>Unstructed Technical Documents</Typography>
            <Divider/>
                <Grid container sx={{display:'flex', flexDirection:'row', }} columnSpacing={1}>
            {
                agent.un_tech_docs?.map((d) => (
                    <Grid item>
                        <Box marginTop={0.25}>
                        <EllipsisChip label={<Typography sx={chipText}>{d.fileName || d.name}</Typography>} onClick={(e) => {e.preventDefault(); downloadFile(d)}}/>
                        </Box>
                    </Grid>
                ))
            }
            </Grid>
            </Box>

            <Box sx={boxSpace}>
            <Typography sx={titleText}>Structured Technical Documents</Typography>
            <Divider/>
                <Grid container sx={{display:'flex', flexDirection:'row', }} columnSpacing={1}>
                {
                    agent.s_tech_docs?.map((d) => (
                        <Grid item>
                        <Box sx={{marginTop:0.25}}>
                            <EllipsisChip label={<Typography sx={chipText}>{d.fileName || d.name}</Typography>} onClick={(e) => {e.preventDefault(); downloadFile(d)}}/>
                        </Box>
                        </Grid>
                    ))
            }
            </Grid>
            </Box>

            {agent.meta_map && Object.values(agent.meta_map).length > 0 && 
            <Box sx={boxSpace}>
            <Typography sx={titleText}>MetaData</Typography>
            <Divider/>
                <Grid container sx={{display:'flex', flexDirection:'row', }} columnSpacing={1}>
                {
                    Object.values(agent.meta_map).flat().map((d) => (
                    <Grid item>
                    <Box sx={{marginTop:0.25}}>
                        <EllipsisChip label={<Typography sx={chipText}>{d.fileName || d.name}</Typography>} onClick={(e) => {e.preventDefault(); downloadFile(d)}}/>
                    </Box>
                    </Grid>
                    ))
            }
            </Grid>
            </Box>}
            <Box sx={boxSpace}>
                <Typography sx={titleText}>Case Studies</Typography>
                <Divider/>
                <Grid container sx={{display:'flex', flexDirection:'row', }} columnSpacing={1}>
                {
                    agent.case_studies?.map((d) => (
                        <Grid item>
                        <Box sx={{marginTop:0.25}}>
                            <EllipsisChip label={<Typography sx={chipText}>{d.fileName || d.name}</Typography>} onClick={(e) => {e.preventDefault(); downloadFile(d)}}/>
                        </Box>
                        </Grid>
                    ))
                }
                </Grid>
            </Box>
        </Box>
    )
}