import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Box, useMediaQuery, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import Markdown from 'react-markdown';
import './markdown.css';

function getResponseSummary(response) {
  if (typeof response === 'string') {
    return response;
  } else if (response.response) {
    return response.response;
  } else if (response.message?.content) {
    return response.message.content;
  } else if(response.text){
    return response.text;
  }
  return "";
}
function skipContextString(text) {
  const parts = text.split(/\n\n/);
  if (parts.length < 2) {
    return text;
  }
  return parts[2];
}

const style = { backgroundColor: '#f5f5f5' };

function SourcesModal({ data, onClose }) {
  const isFullScreen = useMediaQuery(theme => theme.breakpoints.down('sm')); // To handle responsiveness

  return (
    <Dialog
      open={!!data}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      fullScreen={isFullScreen}
    >
      <DialogTitle sx={{ ...style, fontWeight: 500 }}>Sources</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      <DialogContent sx={style}>
        {data.responses?.map((response, i) => (
          <Box key={i} mb={2}>
            <Typography variant="h6" mb={1}>{response.name}</Typography>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>Summary</Typography>
            <Markdown>{getResponseSummary(response)}</Markdown>
            {response.source_nodes && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={500}>Documents</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {response.source_nodes.map((source_node, j) => (
                    <Accordion key={j}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {source_node.node.metadata?.file_name} {source_node.node.metadata?.page_label ? `Page ${source_node.node.metadata?.page_label}` : ""}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Markdown>{skipContextString(source_node.node.text)}</Markdown>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => window.open(`https://sinsen-demo-file.s3.amazonaws.com/${source_node.node.metadata?.file_name}#page=${source_node.node.metadata?.page_label}`, '_blank')}
                          sx={{ mt: 2 }}
                        >
                          View Original File
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
            {response.documents && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={500}>Documents</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {response.documents.map((document, j) => (
                    <Accordion key={j}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {document.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Markdown>{document.snippet}</Markdown>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default SourcesModal;
