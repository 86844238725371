import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import CustomButton from './CustomButton';
import { themeOptions } from '../../Constants/Colors';

export default function ReadMore({ text, maxHeight = 100, onReadMore }){
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflow(textRef.current.scrollHeight > textRef.current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [text]);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      <Box
        ref={textRef}
        sx={{
          maxHeight: isExpanded ? 'none' : maxHeight,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'none' : 3,
          WebkitBoxOrient: 'vertical',
        }}
      >
        <Typography>{text}</Typography>
      </Box>
      {isOverflow && (
        <CustomButton buttonProps={{variant:'text', sx:{color:themeOptions.palette.primary.main}}} style={{width:undefined}} onClick={onReadMore ? onReadMore : toggleReadMore}>
          {isExpanded ? 'Read less' : 'Read more'}
        </CustomButton>
      )}
    </Box>
  );
};
