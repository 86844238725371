import { Autocomplete, Box, Button, IconButton, Paper, TextField, Typography } from "@mui/material";
import { doubleGradient, themeOptions } from "../Constants/Colors";
import {StyledAppBar} from "../components/general/AppBar";
import { Anta, fontSizes } from "../Constants/Fonts";
import { ipsum } from "../Constants/ipsum";
import { expertiseOptions } from "../Constants/DummyInfo";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SearchIcon from '@mui/icons-material/Search';
import RotateSearch from "../components/search/RotateSearch";
import { AnimatePresence, motion } from "framer-motion";

import search from '../assets/images/undraw_search.svg'
import people from '../assets/images/undraw_people.svg'
import chat from '../assets/images/undraw_chatting.svg'
import BackgroundTemplate from "../components/general/BackgroundTemplate";

const howTo = [
    {
        name:'Search For Your Industry',
        image: search
    },
    {
        name: "Pick Your Expert",
        image: people
    },
    {
        name: "Chat!",
        image: chat
    }
]

export default function Search({}){
    const [searchValue, setSearchValue] = useState("")
    const theme = useTheme()
    const navigate = useNavigate()

    const search = (value=searchValue) => {
        console.log('value', value)
        if(value)
            navigate(`/experts?query=${value}`)    
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          search();
        }
    };
    
    return(
        <BackgroundTemplate>
            <Box sx={{display:'flex', flex:1, alignItems:'center', flexDirection:'column', maxWidth:'100%',paddingX:"1rem"}}>
                <Box sx={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column', width:'85rem', maxWidth:'100%', }}>
                    <Typography variant="h4" sx={{fontSize: fontSizes.subtitle, fontFamily:Anta.fontFamily, color:themeOptions.palette.text.title}}>
                        How does SinSen Work?
                    </Typography>
                    <Typography sx={{fontSize:fontSizes.default}}>
                        Sinsen.ai is a platform where subject matter experts can curate Expert AI Agents based on relevant documentation as well as their own professional experience. 
                        These custom-curated agents are trained to reason like the experts they are based on.
                    </Typography>
                    <Typography sx={{fontSize:fontSizes.default, marginTop:0.75}}>
                        Sinsen.ai also serves as a platform for those seeking subject matter expertise to “hire” an Expert AI Agent to analyze and generate insights within a variety of scenarios. 
                        Companies or individuals seeking expertise in fields such as Law, Finance, Medicine, Technology and Marketing can use Sinsen.ai to procure 
                        expertise in the most efficient manner possible.
                    </Typography>
                    <Typography sx={{fontSize:fontSizes.default, marginTop:0.75}}>
                    These Expert Agents can analyze a given scenario and render advice based on trusted referenceable documents as well as human experiences in 
                    similar situations. All analysis will be cited and the supporting documents will be made available to the user.   
                    </Typography>

                   
                </Box>
                <Box sx={{width:'65rem', maxWidth:'100%', display:'flex', flexDirection:'column'}}>
                    <RotateSearch  options={["Expert", "Security Consultant", "Manager", "Legal Expert", "Accountant", "Software Developer"]}
                    fontSize={{xs:fontSizes.default+4, md:fontSizes.default+10}}/>
                    <Autocomplete
                        freeSolo
                        value={searchValue}
                        onChange={(event, newValue) => {
                            search(newValue)
                        }}
                        sx={{borderRadius:5}}
                        options={expertiseOptions.map((option) => option.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Industry"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setSearchValue(e.target.value)}
                                
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <>
                                        <IconButton
                                            edge="end"
                                            color="primary"
                                            onClick={() => search()}
                                            aria-label="search"
                                            style={{marginRight:'1rem'}}
                                        >
                                        <SearchIcon />
                                        </IconButton>
                                        {params.InputProps.endAdornment}
                                    </>
                                    ),
                                }}
                                
                            />
                          )}
                    />
                </Box>
                <Box sx={{width:'85rem', maxWidth:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
                    {howTo.map((h, index) => (
                        <Box sx={{width: "10rem", height:'10rem', maxWidth:'100%', margin:'2rem', display:'flex'}}>
                        <motion.div
                        initial={{ opacity: 0, scale: 0.5, rotate: 360 }}
                        animate={{ opacity: 1, scale: 1, rotate: 0 }}
                        transition={{ duration: 1, delay: index * 0.5 }}
                        style={{ height:'100%', width:'100%',display:'flex',}}
                        >
                            <Paper sx={{ borderRadius: 12, width: '100%', height: '100%', justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column', padding:2 }}>
                                <Typography sx={{fontFamily:Anta.fontFamily, color:theme.palette.text.title, fontSize:fontSizes.default-1}} align='center'>{h.name}</Typography>
                                <Box sx={{objectFit: 'contain',}}>
                                <img
                                    src={h.image}
                                    style={{ width: '100%', height: '100%'}}
                                />
                                </Box>
                            </Paper>
                        </motion.div>
                        </Box>
                    ))}
                </Box>
                
            </Box>
        </BackgroundTemplate>
    )
}