import { useState } from "react";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { getAuthObj } from "../../util/Firebase";

function getApiUrl(){
  return process.env.REACT_APP_API_URL;
}

async function* getCompletion(
  params,
  signal,
) {
  const url = new URL(
    "/queryAI",
    getApiUrl()
  );
  const formData = new FormData();
  for(const key in params) {
    if(Array.isArray(params[key])){
      for(const value of params[key]){
        if(value){
          formData.append(key, value);
        }
      }
    }
    else{
      formData.append(key, params[key]);
    }
  };
  const res = await fetch(url, {
    method: "POST",
    signal,
    body: formData,
    headers: {
      "Authorization": `${getAuthObj().email}`
    }
  });

  const reader = res.body?.getReader();
  if (!reader) throw new Error("No reader");
  const decoder = new TextDecoder();

  let i = 0;
  let readingJSON = false;
  let jsonString = "";
  while (i < 1000) {
    i++;
    const { done, value } = await reader.read();
    if (done) return;
    const token = decoder.decode(value);
    console.log(token)
    if(token.includes("***|FINAL_JSON_DATA_END|***") && token.includes("***|FINAL_JSON_DATA_START|***")){
      let tokenParts = token.split("***|FINAL_JSON_DATA_START|***");
      if(tokenParts.length !== 2){
        throw new Error("Invalid token format");
      }
      if(tokenParts[0]){
        yield tokenParts[0];
      }
      jsonString = tokenParts[1].split("***|FINAL_JSON_DATA_END|***")[0];
      try{
        let parsed = JSON.parse(jsonString);
        yield parsed;
      }
      catch(e){
        yield {error: "Unable to parse JSON"}
      }
    }
    else if(token.includes("***|FINAL_JSON_DATA_END|***")){
      let tokenParts = token.split("***|FINAL_JSON_DATA_END|***");
      if(tokenParts.length !== 2){
        throw new Error("Invalid token format");
      }
      if(tokenParts[0]){
        jsonString += tokenParts[0];
      }
      readingJSON = false;
      try{
        let parsed = JSON.parse(jsonString);
        yield parsed;
      }
      catch(e){
        yield {error: "Unable to parse JSON"}
      }
      jsonString = "";
    }
    else if(readingJSON){
      jsonString += token;
    }
    else if(token.includes("***|FINAL_JSON_DATA_START|***")){
      let tokenParts = token.split("***|FINAL_JSON_DATA_START|***");
      if(tokenParts.length !== 2){
        throw new Error("Invalid token format");
      }
      if(tokenParts[0]){
        yield tokenParts[0];
      }
      jsonString = tokenParts[1];
      readingJSON = true;
    }
    else{
      yield token;
    }
    if (signal?.aborted) {
      await reader.cancel();
      return;
    }
  }
}


export function useChatCompletion(id) {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ["chatCompletion", id],
  });
  const [abortController, setAbortController] = useState();

  const { mutate, error, isPending } = useMutation({
    mutationKey: ["chatCompletion", id],
    mutationFn: async (params) => {
      if (abortController) {
        abortController.abort();
      }
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);
      
      queryClient.setQueryData(
        ["chatCompletion", id],
        (prev) => {
          let newChat = {message: params.prompt, role: "user"}
          let newResponse = {message: "", role: "assistant"}
          console.log([newChat, newResponse])
          if(prev) return [...prev, newChat, newResponse];
          return [newChat, newResponse];
        }
      );
      for await (const token of getCompletion(
        params,
        signal,
      )) {
        queryClient.setQueryData(
          ["chatCompletion", id],
          (prev) => {
            if(typeof token === 'object'){
              if(!prev) return [{data: token}];
              return [...prev.slice(0, prev.length-1), {...prev[prev.length-1], data: token}];
            }
            if(!prev) return [{message: token}];
            return [...prev.slice(0, prev.length-1), {...prev[prev.length-1], message: prev[prev.length-1].message + token}];
          }
        );
      }
      setAbortController(null);
    },
  });

  return [mutate, { data, error, isPending }];
}

export async function getExperts() {
  let resp = await axios.get(`${getApiUrl()}/experts`);
  return resp.data;
}
export async function getEmbedModels() {
  let resp = await axios.get(`${getApiUrl()}/embedModels`);
  return resp.data;
}
export async function getLlms() {
  let resp = await axios.get(`${getApiUrl()}/llms`);
  return resp.data;
}
export async function createExpert(data) {
  let resp = await axios.post(`${getApiUrl()}/createExpert`, data);
  return resp.data;
}
export async function updateExpert(data) {
  let resp = await axios.post(`${getApiUrl()}/updateExpertForUser`, data);
  return resp.data;
}
export async function updateStructuredDocument(data) {
  let resp = await axios.post(`${getApiUrl()}/updateStructuredDocument`, data);
  return resp.data;
}