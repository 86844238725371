import { Box, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Anta, fontSizes } from "../../Constants/Fonts";

const defaultStyle = {display:'flex', flexDirection:'row'}

export default function RotateSearch({options=['Expert', 'Software Developer', 'Manager'], style={}, fontSize=fontSizes.default+10}){
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
          setShow(false);
          setTimeout(() => {
            setIndex((prevIndex) => (prevIndex + 1) % options.length);
            setShow(true);
          }, 1000); // Duration of the fade-out animation
        }, 4000); // Change text every 3 seconds, including fade-in and fade-out durations
    
        return () => clearInterval(interval);
      }, []);

    return (
    <Box sx={{...defaultStyle, ...style}}>
    <AnimatePresence>
        <Typography sx={{marginRight:1, fontSize:fontSize, fontFamily:Anta.fontFamily}}>Find Your</Typography>
        {show && (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }} // Duration of the fade-in and fade-out animations
          >
            <Typography sx={{fontSize:fontSize, fontFamily:Anta.fontFamily}}>{options[index]}</Typography>
          </motion.div>
        )}
      </AnimatePresence>
      </Box>
  );
}