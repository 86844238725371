import { fontSizes } from "./Fonts";

export const doubleGradient="linear-gradient(90deg, rgba(245,245,220,1) 19%, rgba(211,152,127,1) 50%, rgba(209,187,107,1) 100%);"
// export const singleGradient="linear-gradient(270deg, rgba(211,152,127,1) 0%, rgba(209,187,107,1) 100%);"
export const singleGradient="linear-gradient(270deg, rgba(211,152,127,1) 0%, rgba(245,245,220,1) 100%);"

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#2d3c64',
    },
    secondary: {
      main: '#FFDE2F',
    },
    text: {
      contrast: "#F8F1E5",
      primary: "#000000",
      title: "#333333",
    },
    background:{
      paper: "#F8F8EF"
    }
  },
  typography: {
    fontSize: fontSizes.default,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.paper,
          borderRadius: 25,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },
      },
    },
  },
};