import { Component, createElement, useCallback, useEffect, useState } from "react";
import BackgroundTemplate from "../../components/general/BackgroundTemplate";
import { Box, Button, Grid, MobileStepper, Paper, Snackbar, Step, StepButton, Stepper, Typography, useTheme } from "@mui/material";
import ExpertForm from "./ExpertForm";
import CustomButton from "../../components/general/CustomButton";
import TechDocs from "./TechDocs";
import Confirm from "./Confirm";
import StructuredTech from "./StructuredTech";
import CaseStudies from "./CaseStudies";
import ResponseDialog from "../../components/general/ResponseDialog";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useIsMdScreen } from "../../util/hooks";
import { Anta, fontSizes } from "../../Constants/Fonts";
import { makeAuthenticatedRequest } from "../../util/Firebase";
import ErrorSnackBar from "../../components/general/ErrorSnackBar";
import _ from 'lodash'


export default function CreateExpert() {
  const theme = useTheme()

  const isMdScreen = useIsMdScreen();

  
  const handleFileError = (errors) => {
    console.log('errors', errors)

    let newErrorString = "Your files have the following errors:\n"

    for(const err of errors){
      newErrorString += `${err.file.name}: \n`
      for(const fileErr of err.errors){
        switch(fileErr.code){
          case 'file-invalid-type':
            newErrorString += `\tInvalid File Type\n`
            break;
          default:
            newErrorString += `\t${fileErr.message}\n`
            break;
        }
      }
    }

    setErrorString(newErrorString)
  }

  const handleError = (error) => {
    setErrorString(String(error))
  }

  const STEPS = [
    { name: 'Expert Info', Component: ExpertForm },
    { name: 'Unstructured Technical Documents', Component: TechDocs, errorFunc: handleFileError },
    { name: 'Structured Technical Documents', Component: StructuredTech, errorFunc: handleFileError},
    { name: "Case Studies", Component:CaseStudies, errorFunc: handleFileError},
    { name: 'Confirm', Component: Confirm },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [agentInfo, setAgentInfo] = useState({});
  const [stepsCompleted, setStepsCompleted] = useState(STEPS.map(() => false));

  const [respObject, setRespObject] = useState(null)
  const [loading, setLoading] = useState(false)

  const [errorString, setErrorString] = useState("")

  const updateAgentInfo = useCallback((key, value) => {
    setAgentInfo(prevInfo => ({ ...prevInfo, [key]: value }));
  }, []);

  const updateStepCompletion = useCallback((index, status) => {
    setStepsCompleted(prev => {
      const newStepsCompleted = [...prev];
      newStepsCompleted[index] = status;
      return newStepsCompleted;
    });
  }, []);

  useEffect(()=>{
    console.log("agentInfo", agentInfo)
  }, [agentInfo])
  
  const handleNext = () => setCurrentStep(prev => Math.min(prev + 1, STEPS.length - 1));
  const handleBack = () => setCurrentStep(prev => Math.max(prev - 1, 0));
  const handleStep = (step) => setCurrentStep(step);
  
  const readyToSubmit = () => {
    //see if everything except for the last element (confirm) is done
    return stepsCompleted.slice(0, -1).every(val => val === true) && stepsCompleted[stepsCompleted.length - 1] === false;
  }

  const handleSubmit = () => {

    const body = {...agentInfo}

    const structured = body.s_tech_docs.map(d => d.file)
    delete body.s_tech_docs

    const unstructured = body.un_tech_docs.map(d => d.file)
    delete body.un_tech_docs

    const studies = body.case_studies.map(d => d.file)
    delete body.case_studies
    
    //extract metadata from meta_map
    let metadata = []
    if(body.meta_map && !_.isEmpty(body.meta_map)){
      metadata = Object.values(body.meta_map).flat()
      //extract the file
      metadata = metadata.map(m => m .file)

      //extract uuids
      const reducedMeta = Object.keys(body.meta_map).reduce((acc, key) => {
        acc[key] = body.meta_map[key].map(f => f.uuid);
        return acc;
      }, {});
      body.meta_map = reducedMeta
      
    }

    delete body.metadata

    console.log('test', agentInfo)
    console.log('json data', body)
    const formBody = new FormData();
    formBody.append('json_data', JSON.stringify(body))

    //append structured files
    if (structured && structured.length) {
      structured.forEach((file, index) => {
        formBody.append(`tech_docs`, file)
      })
      console.log('structured', structured)
    }
    
    if (metadata && metadata.length) {
      metadata.forEach((file, index) => {
        formBody.append(`metadata_docs`, file)
      })
      console.log('metadocs', metadata)
    }
  
    //append the unstructured files individually
    if (unstructured && unstructured.length) {
      unstructured.forEach((file, index) => {
        formBody.append(`unstructured_tech_docs`, file)
      })
      console.log('unstructured', unstructured)
    }
  
    // Append the case study files individually
    if (studies && studies.length) {
      studies.forEach((file, index) => {
        formBody.append(`case_studies`, file)
      })
      console.log('studies', studies)
    }
    setLoading(true)
    makeAuthenticatedRequest(`${process.env.REACT_APP_API_URL}/manage/experts/create`,{
      method: "POST",
      body: formBody
    }).then(resp => resp.json().then(data => ({ status: resp.ok, json:data })))
    .then(({status, json}) => {
      console.log(json)
      setRespObject({success: status, message: !status ? `Something went wrong with your uploading: ${JSON.stringify(json.detail)}` : 
        "Your information has been sent!  Please give us a few days to verify your expertise and get back to you."})
      setLoading(false)
    })
    .catch(e => {
      console.error(e)
      setRespObject({status: "error", message:`an unknown error occured ${String(e)}`})
      setLoading(false)
    })
  }
  //stepper version
  return (
    <BackgroundTemplate>
      <ErrorSnackBar
      open={Boolean(errorString)}
      onClose={() => setErrorString("")}
      message={
        <Typography sx={{whiteSpace: 'pre-wrap', fontSize:fontSizes.default-5}}>
          {errorString}
        </Typography>
      }
      anchorOrigin={{vertical: "bottom", horizontal:"left"}}
      autoHideDuration={5000}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexGrow:1}}>
        {
          //render different stepper based on screen size
          isMdScreen ? 
          <Box sx={{ 
          width: { xs: '100%', md: '80rem' }, 
          maxWidth: '100%', 
          padding: '1%', 
          paddingBottom: '0%',
          display: 'flex', 
          flexDirection: 'column', 
          }}>
            <Stepper activeStep={currentStep} sx={{ maxWidth: '100%' }}>
              {STEPS.map((step, index) => (
                <Step key={step.name} completed={stepsCompleted[index]}>
                  <StepButton color="inherit" onClick={() => handleStep(index)}>
                    <Typography sx={{ 
                      fontSize: fontSizes.default-2, 
                      color: index === currentStep ? theme.palette.primary.main : undefined, 
                      fontFamily: Anta.fontFamily 
                    }}>
                      {step.name}
                    </Typography>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            
            <Box
              id="content"
              sx={{
                marginTop: '1%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,  // This makes the content grow to fill remaining space
                justifyContent: 'space-between',
              }}
            >
              {createElement(STEPS[currentStep].Component, {
                agent: agentInfo,
                onError: STEPS[currentStep].errorFunc || handleError,
                updateAgent: updateAgentInfo,
                updateComplete: (status) => updateStepCompletion(currentStep, status),
              })}

              {/* Buttons positioned at the bottom */}
              <Box sx={{ width: '100%' }}>
                <Grid container sx={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: '1%' }}>
                  <Grid item container xs={6} sx={{ display: 'flex', padding: '5px' }}>
                    <CustomButton disabled={currentStep === 0} onClick={handleBack}>
                      Back
                    </CustomButton>
                  </Grid>
                  <Grid item container xs={6} sx={{ display: 'flex', padding: '5px' }}>
                    {currentStep !== STEPS.length - 1 ? (
                      <CustomButton disabled={currentStep === STEPS.length - 1 || !stepsCompleted[currentStep]} onClick={handleNext}>
                        Next
                      </CustomButton>
                    ) : (
                      <CustomButton onClick={handleSubmit} disabled={!readyToSubmit()}>
                        Submit
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        : 
        <Box 
          sx={{ 
            width: "100%", 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between'  // Align items at the top and bottom
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box
              square
              elevation={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent:'center',
                minHeight: 50,
                padding: '10px',
              }}
            >
              <Typography 
                align="center" 
                sx={{ 
                  fontSize: fontSizes.titleSmall - 10, 
                  color: theme.palette.primary.main, 
                  fontFamily: Anta.fontFamily 
                }}
              >
                {STEPS[currentStep].name}
              </Typography>
            </Box>
            <Box sx={{ padding: '1%' }}>
              {createElement(STEPS[currentStep].Component, {
                agent: agentInfo,
                updateAgent: updateAgentInfo,
                updateComplete: (status) => updateStepCompletion(currentStep, status),
              })}
            </Box>
          </Box>
          
          <MobileStepper
            variant="progress"
            steps={STEPS.length}
            position="static"
            activeStep={currentStep}
            nextButton={
              <>
              {
                currentStep !== STEPS.length - 1 ?
                <Button
                size="small"
                onClick={handleNext}
                disabled={currentStep === STEPS.length - 1 || !stepsCompleted[currentStep]}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
                :
                <Button
                size="small"
                onClick={handleSubmit}
                disabled={!readyToSubmit()}
                >
                  Submit
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              
              </>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={currentStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>

        }
        
      </Box>
      <ResponseDialog loading={loading} responseObj={respObject} onClose={(e) => {e.preventDefault(); setLoading(false); setRespObject(null)}}/>
    </BackgroundTemplate>
  );
}