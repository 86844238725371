import "../assets/fonts/Anta/Anta-Regular.ttf"


export const fontSizes = {
    default: 20,
    navText: 16,
    title: 100,
    titleSmall: 50,
    subtitle: 50,
    subtitleSmall: 40
}

export const Anta = {
    fontFamily: "Anta-Regular",
    defaultSize:20
}