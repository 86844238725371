import { useTheme } from "@emotion/react";
import { TextField, MenuItem, Select, InputLabel, FormControl, Box, Checkbox, FormControlLabel } from "@mui/material";

export function FieldInput({ name, label, type, value, handleChange, options, ...props }) {
  const theme = useTheme();
  if(name.endsWith("_enabled")) {
    let showFieldName = name.substring(0, name.length - 8)+"_show";
    return (
      <div style={{ display: "flex", flexDirection: 'row' }}>
        <FormControl variant="outlined" fullWidth {...props}>
          <FormControlLabel control={
            <Checkbox
              checked={value}
              onChange={(e) => handleChange(name, e.target.checked)}
            />
          } 
          label={label} 
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth {...props}>
          <FormControlLabel control={
            <Checkbox
              checked={!!props.data[showFieldName]}
              onChange={(e) => handleChange(showFieldName, e.target.checked)}
            />
          } 
          label={"Show Instructions"} 
          />
        </FormControl>
      </div>
    )
  }
  switch (type) {
    case "text":
      return (
        <TextField
          label={label}
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange(name, e.target.value)}
          value={value}
          {...props}
        />
      );
    case "string":
      return (
        <TextField
          label={label}
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange(name, e.target.value)}
          value={value}
          {...props}
        />
      );
    case "int":
      return (
        <TextField
          label={label}
          type="number"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange(name, e.target.value)}
          value={value}
          {...props}
        />
      );
    case "select":
      return (
        <FormControl variant="outlined" fullWidth {...props}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={value}
            onChange={(e) => handleChange(name, e.target.value)}
            sx={{bgcolor: theme.palette.background.paper}}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "multiselect":
      return (
        <FormControl variant="outlined" fullWidth {...props}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            multiple
            value={value}
            onChange={(e) => handleChange(name, e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "boolean":
      return (
        <FormControl variant="outlined" fullWidth {...props}>
          <FormControlLabel control={
            <Checkbox
              checked={value}
              onChange={(e) => handleChange(name, e.target.checked)}
            />
          } 
          label={label} 
          />
        </FormControl>
      )
    default:
      return <Box>Error</Box>;
  }
}