import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar as MuiBar, 
    Toolbar, 
    Button, 
    IconButton, 
    Typography,
    Grid,
    Drawer,
    Box,
    List,
    Divider,
    ListItem,
    ListItemText,
    ListItemButton,
    Tab,
    Tabs,
    useTheme
} from '@mui/material'
import { useEffect, useState } from 'react';
import { navRoutes } from '../../index';
import { matchRoutes, useLocation, useNavigate } from 'react-router';
import {motion} from 'framer-motion'
import { signOut } from '../../util/Firebase';
import ColorHover from './ColorHover';
import CustomButton from './CustomButton';
import { fontSizes } from '../../Constants/Fonts';

function DrawerContents({navItems, homeObj, onClose}){
    const navigate = useNavigate()
    const theme = useTheme()
    return(
    <Box onClick={onClose} sx={{ textAlign: 'center', width:'12rem', height:'100%', background:theme.palette.primary.main }} >
      <Box sx={{cursor:'pointer'}} onClick={() => navigate('/')}>
        <ColorHover hoverColor={theme.palette.secondary.main}>
        <Typography variant="h6" sx={{ my: 2 }}>
            SinSen
        </Typography>
        </ColorHover>
      </Box>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(item.path)}>
                <ColorHover hoverColor={theme.palette.secondary.main} >
                <ListItemText primary={item.name} />
                </ColorHover>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
    )
}



function HoverTab(props){
    const theme = useTheme()
    return <Tab {...props} sx={{
        transition: 'all 0.2s',
        color:theme.palette.text.contrast,
        fontSize: fontSizes.navText+5,
        textTransform:"none",
        "&:hover":{
            color:theme.palette.secondary.main
        }}}/>
}

export default function AppBar({style}){
    const location = useLocation()
    const navigate = useNavigate()
    const homeObj = {
        name: "Home",
        path: "/"
    }

    const [drawerVis, setDrawerVis] = useState(false)
    const [selectedRoute, setSelectedRoute] = useState(location.pathname)
    const theme = useTheme()

    const handleSelect = (event, value) => {
        console.log(event, value)
        setSelectedRoute(value)
        navigate(value)
    }

    return(
        <>
        <MuiBar position="sticky" component="nav" sx={style}>
        <Toolbar>
        <Grid container sx={{width:'100%', display:'flex', justifyContent:'center', alignitems:'center', flexDirection:'row'}}>
          <Grid item container sx={{display: {xs:'flex', sm:'flex', md:'none'}}} xs={4}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerVis(true)}
            >
                <ColorHover hoverColor={theme.palette.secondary.main}>
                <MenuIcon />
                </ColorHover>
                
            </IconButton>
          </Grid>
          <Grid container  sx={{justifyContent:'flex-start', alignItems:'center', display: {xs:'none', sm:'none', md:'flex'}}} md={2}>
            <Grid container item md={2}>
            <Box sx={{display:'flex', cursor:'pointer'}} onClick={() => navigate(homeObj.path)}>
                <ColorHover initColor={selectedRoute === '/' ? theme.palette.secondary.main : theme.palette.text.contrast}
                hoverColor={theme.palette.secondary.main}>
                    <Typography
                    variant="h6"
                    component="div"
                    fontSize={fontSizes.navText + 8}
                    sx={{ flexGrow: 1 }}
                    
                    >
                        SinSen
                    </Typography>
                </ColorHover>
            </Box>
            </Grid>
          </Grid>
          <Grid container item xs={8} md={10} sx={{justifyContent:'flex-end', alignitems:'center'}}>
          {/* <Tabs value={selectedRoute} onChange={handleSelect}
                sx={{display: {md: 'flex', xs:'none'}, marginRight:'1.5rem'}} 
                aria-label="navigation tabs"
                textColor="secondary"
                TabIndicatorProps={{
                    style: {
                        backgroundColor: theme.palette.secondary.main // Change the indicator color to white
                    }
                }}
                >
                {navRoutes.map((p, index) => (
                    <HoverTab value={p.path} label={p.name} key={`tab_${p.name}`}/>
                ))}
            </Tabs> */}
            <Box sx={{display: {md: 'flex', xs:'none'}}}>
            {navRoutes.map((p, index) => (
                    <CustomButton color="inherit" onClick={(e) => handleSelect(e, p.path)} style={{width:undefined}} buttonProps={{variant:"text"}} key={`tab_${p.name}`} to={1}>
                        <Typography 
                            variant="h6"
                            component="div"
                            fontSize={fontSizes.navText+5}>
                            {p.name}
                        </Typography>
                    </CustomButton>
                ))}
            </Box>
            <CustomButton color="inherit" onClick={() => signOut()} style={{width:undefined}} buttonProps={{variant:"text"}}>
                <Typography 
                    variant="h6"
                    component="div"
                    fontSize={fontSizes.navText+5}>
                    Sign Out
                </Typography>
            </CustomButton>
          </Grid>
        </Grid>
        </Toolbar>
        </MuiBar>
        <Drawer
        anchor="left"
        variant="temporary"
        open={drawerVis}
        onClose={() => setDrawerVis(false)}
        ModalProps={{
            keepMounted: true, // Better open performance on mobile.
        }}
        >
            <DrawerContents onClose={() => setDrawerVis(false)} navItems={navRoutes}/>
        </Drawer>
        </>
    )
}

export function StyledAppBar({style={borderRadius: 40}, boxStyle={paddingX:'1rem', paddingY:'0.5rem'}}){
    return(
    <Box sx={boxStyle}>
        <AppBar style={style}/>
    </Box>
    )
}
