import {motion} from 'framer-motion'
import { themeOptions } from '../../Constants/Colors'

export default function ColorHover({children, hoverColor=themeOptions.palette.secondary.main, initColor=themeOptions.palette.text.contrast}){
    return(
        <motion.div
            initial={{ color: initColor }}
            whileHover={{ color:  hoverColor}}
            transition={{ duration: 0.3 }}
            style={{color:initColor}}
        >
            {children}
        </motion.div>
    )
            
}