import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut as firebaseSignOut,
    onAuthStateChanged,
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_GCP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_GCP_FIREBASE_DOMAIN
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const tenantId = process.env.REACT_APP_TENANT_ID;
if(tenantId)
    auth.tenantId = tenantId;



listenAuthChange((params) => {
    if(params?.auth?.currentUser){
        axios.defaults.headers.common['Authorization'] = auth.currentUser.email;
        // not going to bother with authentication on backend right now, change to below later
        // auth.currentUser.getIdToken().then(token => {
        //     axios.defaults.headers.common['Authorization'] = token;
        // })
    }
})

export const emailSignIn = async (username, password) => {
    let authObj = await signInWithEmailAndPassword(auth, username, password);
    return authObj;
}

export function getAuthObj(){
    return auth.currentUser;
}

export async function signOut(){
    await firebaseSignOut(auth);
}

export function isSignedIn() {
    return new Promise((resolve) => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            resolve(Boolean(user));
            unsubscribe(); // Clean up the listener after resolving
        });
    });
}

export function listenAuthChange(callback){
    return onAuthStateChanged(auth, callback);
}

// New function to get the current user's ID token
export async function getIdToken() {
    const user = auth.currentUser;
    if (user) {
        return user.getIdToken();
    }
    return null;
}

export function getUserEmail(){
    return auth.currentUser.email
}


// Example function to make authenticated requests to your backend
export async function makeAuthenticatedRequest(url, options = {}) {
    const token = await getIdToken();
    if (token) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}`
        };
        return fetch(url, options);
    } else {
        throw new Error('User is not authenticated');
    }
}