import { v4 as uuidv4 } from 'uuid';
export class UnqiueFile{

    constructor(file, fileName=undefined) {
        this.uuid = uuidv4().toString();
        this.fileName = fileName || file.name;
        this.file = new File([file], this.uuid, {
            type: file.type,
            lastModified: file.lastModified
        });    
    }
}