import { Box, Typography, useTheme } from "@mui/material";
import Typewriter from 'typewriter-effect';
import { Anta, fontSizes } from "../Constants/Fonts";
import { useState } from "react";
import BackgroundTemplate from "../components/general/BackgroundTemplate";

const blurb = "Sinsen combines the latest advancements in Artificial Intelligence and Cognitive Science to create a platform where world class subject matter experts can curate Agentic Representations of themselves to help enterprises solve their most pressing problems.  The company was founded by a group of professional services experts and technologists that have served a large number global institutions and understand the needs of complex organizations. "

export default function About({}){
    const [typewriterFinished, setTypewriterFinished] = useState(false)

    const theme = useTheme()

    return(
        <BackgroundTemplate>
            <Box sx={{display:'flex', width:'100%', flexDirection:'column'}}>
                <Box sx={{width:'100%', justifyContent:'center', alignItems:'center', display:'flex',}}>
                    <Box sx={{width:'85rem', maxWidth:'100%', justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}>
                    <Typography
                    sx={{ fontSize: { xs: fontSizes.titleSmall, md: fontSizes.title }, color: theme.palette.primary.main, fontFamily: Anta.fontFamily}}
                    >
                        {typewriterFinished ? 'What is SinSen.Ai?' :
                        <Typewriter
                        options={{
                            autoStart: true,
                            loop: false,
                            delay: 50,
                            cursor: "_",
                        }}
                        onInit={(typewriter) => {
                            typewriter
                            .typeString('What is SinSen.Ai?')
                            .callFunction(() => {
                                // Start the second typewriter effect after the first one finishes
                                setTypewriterFinished(true);
                            })
                            .start();
                        }}
                        />}
                    </Typography>
                    <Typography>{blurb}</Typography>
                </Box>
                </Box>
            </Box>
        </BackgroundTemplate>
    )
}